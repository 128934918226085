<template>
  <div>
    <navBar />
    <div class="box">
      <div class="safe2">
        <div class="top">
          <div class="topHead">
            <p>置顶课程</p>
            <el-button
              type="primary"
              icon="el-icon-plus"
              class="loginBtn"
              @click="joinClassBtn"
              >加入课程</el-button
            >
          </div>
          <div class="content">
            <div class="item">
              <div class="top">
                <p>2021-2022全年</p>
                <p>PR2101视频剪辑全能班</p>
                <p>PR2101(PR1班)</p>
                <div class="code">
                  <img src="../../assets/QRcode.png" alt="" />
                  <span>加课码:PNTJFZ</span>
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  <span>学</span>
                  <p>负责人：徐攀</p>
                </div>
                <div class="right">取消置顶</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <joinClassDialog ref="joinClassDialog" />
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar.vue";
import joinClassDialog from "@/components/joinClass/joinClassDialog.vue";
export default {
  data() {
    return {};
  },
  methods: {
    joinClassBtn() {
      this.$refs.joinClassDialog.show();
    },
  },
  components: { navBar, joinClassDialog },
};
</script>
<style lang="scss" scoped>
.safe2{
    max-width: 85vw;
    margin: auto;
}
.top {
  width: 100%;
  border: 1px solid #dadce0;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  .topHead {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 24px;
      color: #575a5b;
      font-weight: 500;
    }
    .loginBtn {
      padding: 16px;
      box-sizing: border-box;
      font-size: 18px;
      background: #4285f4;
      &:hover {
        background: #689df6;
      }
    }
  }
  .content {
    margin-top: 25px;
    .item {
      width: 330px;
      border: 1px solid #e2e6ed;
      border-radius: 8px;
      cursor: pointer;
      .top {
        width: 100%;
        height: 200px;
        background: url("../../assets/01.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        background-color: #3367d5;
        padding: 18px 24px;
        color: #fff;
        box-sizing: border-box;
        p {
          &:nth-child(1) {
            opacity: 0.6;
            font-size: 16px;
          }
          &:nth-child(2) {
            font-size: 22px;
            margin-top: 10px;
          }
          &:nth-child(3) {
            font-size: 16px;
            margin-top: 10px;
          }
        }
        .code {
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 18px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 4px;
          }
        }
      }
      .bottom {
        padding: 60px 20px 20px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            border: 1px solid #4285f4;
            color: #4285f4;
            padding: 2px 4px;
            box-sizing: border-box;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
