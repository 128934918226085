<template>
  <div>
    <el-dialog
      title="加入课程"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 8px !important;
  overflow: hidden;
  .el-dialog__header {
    border-bottom: 1px solid #dadce0;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-dialog__title {
    font-size: 22px;
    font-weight: bold;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 36px;
    font-weight: bold;
  }
  .el-dialog__footer {
    border-top: 1px solid #dadce0;
  }
}

.content {
  padding: 20px 0;
  box-sizing: border-box;
}
</style>
